/// <reference path="../suxesiv.ts" />
/// <reference path="../../../typings/index.d.ts" />

namespace Suxesiv {
    export class MobileDropdownNav {
        protected element: JQuery;
        protected screenSizeThreshold: Number;
        protected toggle: JQuery;

        /**
         *
         * @param {{JQuery|Node|String}} element
         * @param {{Number}} screenSizeThreshold
         */
        public constructor(element: JQuery|Node|String, screenSizeThreshold: Number) {
            this.element = $(element);
            this.screenSizeThreshold = screenSizeThreshold;
            this.toggle = $('[data-mobile-dropdown-nav-toggle]', this.element);

            // events
            $(window).resize(this.eventResize.bind(this));
            this.toggle.on('click touch', this.eventClick.bind(this));

            // init
            this.eventResize();
        }

        protected eventResize() {
            if (this.screenSizeThreshold >= $(window).width()) {
                this.enable();
            } else {
                this.disable();
            }
        }

        protected eventClick(event: Event) {
            if (this.isEnabled()) {
                event.preventDefault();
                this.element.toggleClass('open');
            }
        }

        public enable() {
            if (!this.isEnabled()) {
                this.element.addClass('mobile-dropdown-nav-enabled');
            }
        }

        public disable() {
            if (this.isEnabled()) {
                this.element.removeClass('mobile-dropdown-nav-enabled');
            }
        }

        /**
         *
         * @returns {boolean}
         */
        protected isEnabled(): boolean {
            return this.element.hasClass('mobile-dropdown-nav-enabled');
        }
    }
}
